<template>
  <div class="container">
    <div class="hh_top" v-show="upStyle == 'filesList'">
      <p>待上传文件</p>
      <el-button
        class="hh_construction bg_blue box_shadow"
        icon="el-icon-plus"
        @click="selectFile"
        >选择文件</el-button
      >
      <!-- multiple -->
      <input
        v-show="false"
        ref="fileRef"
        :accept="acceptList"
        type="file"
        id="f"
        @change="fileChange($event)"
      />
    </div>
    <div class="hh_center">
      <ul v-for="(item, index) in upList" :key="index">
        <li>
          <span class="hh_files color_blue">{{ item.text }}</span>
          <span class="hh_files hh_files_num"
            >大小:&nbsp;{{ item.fileSize }}M</span
          >
        </li>
        <li>
          <div class="hh_delete" @click="getDeleteCatalog(index)">
            <i class="el-icon-close"></i>
          </div>
          <el-input v-model="item.fileName" maxlength="50" show-word-limit>
            <template slot="prepend">标题</template>
          </el-input>
        </li>
        <el-progress
          class="hh_prog"
          :percentage="item.percentage"
        ></el-progress>
      </ul>
    </div>
    <p class="hh_bottom">{{ action }}</p>
    <div class="hh_btn_wrap">
      <el-button
        class="hh_construction hh_btn bg_blue box_shadow"
        @click="startUpLoad"
        :disabled="isDisabled"
      >
        确定上传
      </el-button>
    </div>
  </div>
</template>
<script>
import upLoadAPI from "@apis/backStageEndAPI/coursewareManagementAPI";
import API from "@apis/common";
var COS = require("cos-js-sdk-v5");
var cos = new COS({
  getAuthorization: async function (options, callback) {
    // 异步获取临时密钥
    await API.getCosToken().then((data) => {
      if (!data.credentials) return console.error("credentials invalid");
      var credentials = data && data.credentials;
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    });
  },
});
export default {
  name: "allUpLoad",
  props: ["action", "actionType", "upStyle"],
  watch: {
    callbackUrl(val) {
      if (val) {
        this.upcallbackUrl = val;
      }
    },
  },
  data() {
    return {
      timer: [],
      isDisabled: true,
      acceptList: [],
      formData: [
        {
          fileExt: "",
          fileGroup: "",
          fileId: "",
          fileName: "",
          filePath: "",
        },
      ],
      files: [],
      Bucket: "slyl-edu-static-1301295327",
      Region: "ap-beijing",
      upcallbackUrl: [],
      upList: [],
      percentage: 0,
      mimeType: ["docx", "doc", "pdf", "ppt", "pptx"],
      format: ["mp4", "3gp", "mov", "wmv", "mpeg", "flv", "rmvb"],
      // mimeType: ['docx', 'doc', 'pdf', 'mp4', '3gp', 'mov', 'wmv', 'mpeg', 'flv', 'ppt', 'rmvb','pptx'],
    };
  },
  mounted() {
    if (this.actionType == 1) {
      this.acceptList = ".doc, .ppt, .pdf,.pptx,.docx,.word";
    } else {
      this.acceptList = ".wmv,.mov, .rm, .mpeg, .mp4, .3gp, .flv, .avi, .rmvb";
    }
  },
  methods: {
    getDeleteCatalog(index) {
      this.upList.splice(index, 1);
      this.upcallbackUrl.splice(index, 1);
    },
    selectFile() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click")); //弹出选择本地文件
    },
    fileChange(e) {
      this.isDisabled = true;
      var _this = this;
      var file = e.target.files[0];
      let files = e.target.files;
      //获取视频或者音频时长
      var fileurl = URL.createObjectURL(file);
      var audioElement = new Audio(fileurl);
      audioElement.addEventListener("loadedmetadata", function (_event) {
        let tim = parseInt(audioElement.duration);
        _this.timer.push(tim);
      });
      Array.from(files).forEach((item) => {
        let { name, size, webkitRelativePath } = item;
        this.upList.push({
          fileName: name,
          fileSize: (size / 1024 / 1024).toFixed(2),
          filePath: webkitRelativePath,
          text: name,
          fileExt: name.substring(name.lastIndexOf(".") + 1, name.length),
          fileGroup: this.actionType,
          percentage: 0,
        });
      });
      if (!file) return;
      /* 限制文件上传类型 */
      let suffixName = file.name.substring(
        file.name.lastIndexOf(".") + 1
      ); /* 得到文件后缀名 */
      if (
        suffixName == "docx" ||
        suffixName == "doc" ||
        suffixName == "pdf" ||
        suffixName == "ppt" ||
        suffixName == "pptx"
      ) {
        if (!this.mimeType.includes(suffixName)) {
          this.$message.warning(
            "上传文件只能是docx、doc、pdf、ppt、pptx的格式!"
          );
          return;
        }
      }
      if (
        suffixName == "mp4" ||
        suffixName == "3gp" ||
        suffixName == "mov" ||
        suffixName == "wmv" ||
        suffixName == "mpeg" ||
        suffixName == "flv" ||
        suffixName == "rmvb"
      ) {
        if (!this.format.includes(suffixName)) {
          this.$message.warning(
            "上传文件只能是mp4、3gp、mov、wmv、mpeg、flv、rmvb的格式!"
          );
          return;
        }
      }
      // if (!this.mimeType.includes(suffixName)) {
      //   this.$message.warning('上传文件只能是docx、doc、pdf、ppt、pptx的格式!')
      //   return
      // }
      Array.from(files).forEach((item) => {
        let index = file.name.indexOf("."),
          name = file.name.substr(0, index) + new Date().getTime(),
          fileName = this.$md5(name) + "." + suffixName;
        cos.putObject(
          {
            //上传对象
            Bucket: this.Bucket, //存储桶
            Region: this.Region, //存储桶所在地域
            StorageClass: "STANDARD",
            Key: "/upload/files/" + fileName,
            Body: file,
            onProgress: function (progressData, callback) {
              _this.upList.forEach((ele) => {
                if (ele.fileName === item.name) {
                  ele.percentage = parseInt(progressData.percent * 100);
                }
              });
            },
          },
          function (err, data) {
            cos.getObjectUrl(
              {
                //获取不带签名的对象的 Url
                Bucket: _this.Bucket,
                Region: _this.Region,
                Key: "/upload/files/" + fileName,
                Sign: false,
              },
              async function (err, data) {
                _this.upcallbackUrl.push(data.Url);
              }
            );
            _this.isDisabled = !_this.upList.every((i) => i.percentage == 100);
          }
        );
      });
      e.target.value = "";
    },
    startUpLoad() {
      this.formData = this.upList;
      this.formData.forEach((e, ind) => {
        this.upcallbackUrl.forEach((ele, index) => {
          delete e.text;
          if (ind == index) {
            e.filePath = ele;
          }
        });
        this.timer.forEach((el, inde) => {
          if (ind == inde) {
            e.duration = el;
          }
        });
      });
      upLoadAPI.addFileList(this.formData).then(() => {
        this.$emit("showLoads", false);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.hh_top {
  display: flex;
  justify-content: space-between;
  p {
    margin-top: 8px;
    color: #7d8592;
  }
}
.hh_center {
  position: relative;
  margin: 10px auto;
  padding-bottom: 10px;
  min-height: 89px;
  border-radius: 14px;
  background: #f4f9fd;
  li {
    display: flex;
    justify-content: space-between;
    padding: 3px 18px;
  }
  .hh_delete {
    position: absolute;
    left: 18px;
    z-index: 999;
    //删除三角样式
    width: 28px;
    height: 28px;
    border-top-left-radius: 7px;
    background: linear-gradient(315deg, transparent 50%, var(--colorBlue) 51%)
      no-repeat;
    background-size: 28px 28px;
    cursor: pointer;
    .el-icon-close {
      display: block;
      margin: 2px 0 0 2px;
      color: #fff;
      font-weight: 800;
      font-size: 13px;
    }
  }
  .hh_files {
    display: block;
    overflow: hidden;
    text-align: left;
    text-decoration: underline;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;

    overflow-wrap: break-word;
  }
  .hh_files_num {
    color: rgba(125, 133, 146, 1);
    text-align: right;
    cursor: default;
  }
}
.hh_bottom {
  color: #ced5e0;
  font-weight: 400;
  font-size: 12px;
}
.hh_construction {
  width: 110px;
  height: 35px;
  border-radius: 14px;
  color: white;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}
.hh_btn_wrap {
  text-align: right;
  .hh_btn {
    margin-top: 30px;
    width: 110px;
    height: 48px;
    font-size: 14px;
  }
}
.hh_prog {
  padding: 0 20px 0 40px;
  ::v-deep.el-progress__text {
    font-size: 12px !important;
  }
}
</style>
