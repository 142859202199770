<template>
  <div class="wxq_course_header">
    <div class="wxq_course_header_left">
      <el-form class="form" :inline="true">
        <el-row :gutter="16">
          <el-col :span="5">
            <el-form-item label="关键词">
              <el-input
                v-model="formData.keyword"
                placeholder="请输入关键词"
                clearable
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="上传时间">
              <el-date-picker
                class="wxq_time"
                v-model="timeValue"
                type="datetimerange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="9" class="gl_search">
            <el-button
              class="btn btn_blue"
              icon="el-icon-search"
              @click="search"
              >搜索</el-button
            >
            <el-button
              v-if="manageTxt"
              class="btn btn_light_green"
              @click="manage"
              icon="el-icon-help"
              >批量管理</el-button
            >
            <el-button
              v-else
              class="btn btn_light_green"
              icon="el-icon-finished"
              @click="success"
              >完成操作</el-button
            >
            <el-button
              v-if="active"
              icon="el-icon-download"
              class="btn btn_light_green"
              @click="pullVideo"
              >云端拉取</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <!-- <div class="gl_total">
              <label>存储容量</label>
              <p class="hh_num_wrap"><big>36.88</big><small>GB</small></p>
            </div> -->
        </el-row>
      </el-form>
    </div>
    <div class="wxq_course_header_right">
      <div class="wxq_count">
        <div class="wxq_title">
          {{ active == "1" ? "视频数量" : "文档数量" }}
        </div>
        <div class="wxq_count_number color_blue">
          <span class="wxq_number">{{ num }}</span>
          <span>个</span>
        </div>
        <div class="wxq_construction btn_orange box_shadow" @click="newBuild">
          <i class="el-icon-plus"></i>
          <span>上传课件</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headers",
  props: ["active", "data", "num"],
  data() {
    return {
      manageTxt: true,
      options: [
        {
          value: "0",
          label: "上传时间",
        },
      ],
      formData: {
        page: 1,
        pageSize: 20,
        endTime: "",
        fileGroup: 1,
        keyword: "",
        startTime: "",
      },
      timeValue: [],
    };
  },
  mounted() {
    this.formData = Object.assign({}, this.data);
  },
  methods: {
    newBuild() {
      this.$emit("showLoads", true);
    },
    // 批量管理
    manage() {
      this.manageTxt = false;
      this.$emit("showCheck", true);
    },
    // 完成
    success() {
      this.manageTxt = true;
      this.$emit("showCheck", false);
    },
    pullVideo() {
      this.$emit("showPullOne", true);
    },
    search() {
      this.formData.startTime = this.timeValue ? this.timeValue[0] || "" : "";
      this.formData.endTime = this.timeValue ? this.timeValue[1] || "" : "";
      if (this.active) {
        this.$emit("getSearch", this.formData);
      } else {
        this.$emit("getFilter", this.formData);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.wxq_course_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 24px;
  width: 100%;
  border-radius: 24px;
  background: #fff;
  .wxq_course_header_left {
    position: relative;
    padding-right: 20px;
    // width: 82%;
    height: 100%;
    font-size: 14px;
    flex: 1;
  }
  .wxq_course_header_left:before {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 10%;
    left: auto;
    width: 1px;
    height: 80%;
    background-color: #f1f1f1;
    content: "";
  }
  .wxq_course_header_right {
    position: relative;
    padding: 0 16px;
    width: 240px;
    height: inherit;
    text-align: center;
    .wxq_count {
      position: relative;
      width: 100%;
      height: 90%;
      min-height: 160px;
      border-radius: 24px;
      background: #f4f9fd;
      text-align: center;
      .wxq_title {
        padding-top: 12px;
        color: #666;
      }
      .wxq_count_number {
        position: relative;
        top: 12%;
        height: 48px;
        font-weight: 800;
        font-size: 16px;
        line-height: 48px;
        .wxq_number {
          margin-right: 6px;
          font-size: 60px;
        }
      }
      .wxq_construction {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        border-radius: 14px;
        cursor: pointer;
        span {
          color: #fff;
          font-weight: 800;
          font-size: 16px;
          line-height: 24px;
        }
        .el-icon-plus:before {
          margin-right: 13px;
          color: #fff;
          font-weight: 800;
          font-size: 20px;
        }
      }
    }
  }
  .form {
    .wxq_label {
      ::v-deep .el-form-item__label {
        line-height: 28px;
      }
    }
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    ::v-deep .el-form-item__content {
      line-height: normal;
    }
    .wxq_time {
      width: auto;
    }
  }
}
.hh_num_wrap {
  display: block;
  float: left;
  padding: 0 16px;
  min-width: 120px;
  height: 40px;
  border-radius: 8px;
  background: #f4f9fd;
  text-align: center;
  line-height: 40px;
}
</style>
